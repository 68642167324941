export default {
  title: '开始使用 Engagelab',
  tips: {
    signTips: ['没有账户？', '前去注册'],
    splitTip: '或',
    rememberTip: '记住密码',
    forgetTip: '忘记密码',
  },
  formInfo: {
    email: {
      name: '电子邮箱地址',
      placeholder: '建议您使用公司电子邮箱',
      errTip: '请输入您的工作电子邮箱',
      errTip1: '电子邮件无效',
      errTip2: '电子邮箱不存在，是否',
    },
    password: {
      name: '密码',
      placeholder: '大小写字母+数字组合，长度不少于8个字符',
      errTip: '大小写字母+数字组合，长度不少于8个字符',
      errTip1: '包含至少8个字符',
      errTip2: '不符合包含小写字母(a-z)和大写字母(A-Z)的条件',
      errTip3: '不符合至少包含一个数字(0-9)或一个符号的条件',
    },
  },
  muchTip: '登录失败次数过多，请5分钟后再试',
  btn: '登录',
}
