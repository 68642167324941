export default {
  title: '联系商务',
  content: '联系我们获取更详细的产品介绍',
  sub: [
    { name: '名', placeholder: '您的名', errTip: '请输入您的名' },
    { name: '姓', placeholder: '您的姓', errTip: '请输入您的姓' },
    { name: '公司名称', placeholder: '您的公司名称', errTip: '请输入您的公司名称' },
    { name: '其他信息', remark: '可选', placeholder: '请说明您的要求，我們的客户顾问将与您联系' },
  ],
  sub1: [
    { name: '联系人姓名', placeholder: '联系人姓名', errTip: '请输入联系人姓名' },
    { name: '国家', placeholder: '国家', errTip: '请选择国家' },
    { name: '其他信息', placeholder: '其他信息（可选）' },
  ],
  left: {
    title: 'EngageLab 助您一臂之力，全方位触达客户',
    sub: ['全球高送达率', '百亿消息发送量级', '友好的开发者API', '专业技术支持'],
  },
  modeTitle: '联系方式',
  mode: [
    {
      name: '电子邮件',
      placeholder: 'name@company.com',
      errTip: '请输入您的电子邮件',
      errTip1: '请输入正确的电子邮件地址',
    },
    {
      name: '电话号码',
      placeholder: '您的电话号码',
      errTip: '请输入您的电话号码',
    },
    {
      name: 'WhatsApp',
      placeholder: '您的WhatsApp',
      errTip: '请输入您的WhatsApp',
    },
    {
      name: 'skype',
      placeholder: '您的skype',
      errTip: '请输入您的skype',
    },
    {
      name: 'line',
      placeholder: '您的line',
      errTip: '请输入您的line',
    },
    {
      name: 'Telegram',
      placeholder: '您的Telegram',
      errTip: '请输入您的Telegram',
    },
  ],
  btn: '提交',
  successTitle: '提交成功！',
  successText: '我們会尽快与您联系',
}
