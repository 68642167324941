export default {
  title: '查看您的电子邮件',
  tips: {
    signTips: ['已经拥有账户了？', '登录'],
    emailSendTip: '我们已向您发送邮件，请检查电子邮箱中的验证码信息',
    countDownTip: ['请于', '秒后重新获取'],
    retryTip: '重新获取',
  },
  formInfo: {
    verifyCode: {
      name: '验证码',
      placeholder: '请输入验证码',
      errTip: '请输入验证码',
      errTip1: '请输入正确的验证码',
    },
  },
  btn: '下一步',
}
