export default {
  lastUpdated: '最新更新',
  catalog: '本页目录',
  all: '全部',
  noData: '暂无数据',
  noSearchResult: '暂无搜索结果',
  documentation: '文档',
  switchThemeLightTips: '点击切换浅色背景',
  switchThemeDarkTips: '点击切换浅色背景',
  popThemeTips: '点击在小浮窗显示',
  codePop: '代码浮窗',
  codePopShow: '此代码块在浮窗中显示',
  closePop: '点击关闭弹窗',
  copyCodeTips: '点击复制代码',
  copySuccess: '复制成功',
  darkTheme: '深色',
  lightTheme: '浅色',
  searchDocs: '搜索文档',
}
