export default {
  banner: {
    title: '覆盖全球的短信触达能力',
    subTitle: '通过EngageLab易于集成的短信，以及交互式个性化解决方案触达全球客户',
    mainBtn: '免费试用',
    subBtn: '联系销售',
  },
  scene: {
    title: '满足各类场景的短信类型',
    subTitle:
      '通过我们的智能短信营销平台高效触达客户，促进业务发展。此外，EngageLab还提供4种不同的短信营销解决方案，可以满足不同的需求，促进与客户的互动',
    sub: [
      {
        title: 'OTP验证码',
        text: '5秒触达，高质量专用通道，金融级安全保护；适用于注册登录验证、找回密码、支付认证等场景',
      },
      {
        title: '营销推广',
        text: '支持丰富多样的推广短信，大容量高并发处理，为高峰期发送短信提供保障；适用于电商活动、新品宣传、会员服务等',
      },
      {
        title: '服务通知',
        text: '送达率99％，实时监控智能调度，24小时运维保障；适用于日程通知、活动会议邀请、企业信息公告等',
      },
      {
        title: '交易短信',
        text: '通过EngageLab短信营销解决方案向客户发送交易短信，用于确认订单、发票信息、物流通知等，减少人力成本的同时提供更优质的客户服务',
      },
    ],
  },
  reason: [
    {
      title: '全球发送能力',
      desc: [
        'EngageLab短信营销解决方案可以在全球范围内合法地触达客户，促进您的全球业务发展',
        '通过API 直连 200+国家/地区的运营商短信服务',
        '严格遵从全球安全隐私法规，包括国际GDPR、CCPA等法规',
      ],
    },
    {
      title: '智能发送策略',
      desc: [
        '我们的智能发送策略可满足您在短信发送速度、送达率和成本效益方面的要求。此外，短信营销解决方案十分灵活，能够为客户发送定制短信',
      ],
    },
    {
      title: '自动化技术',
      desc: [
        'EngageLab短信营销解决方案兼具智能化和自动化功能，可确保信息的安全性和送达效率',
        '搭建全球监控系统，优化和排除通道性能故障',
        '通过弹性服务器和通道实现更高和更快的吞吐量',
        '先进的安全系统保护您和您的客户免受欺诈和垃圾短信的侵害',
      ],
    },
  ],
  function: {
    title: '快速稳定的短信发送服务',
    subTitle:
      'EngageLab可以提供快捷、稳定的短信发送服务和营销解决方案，满足客户在不同场景下的需求。您可以通过高效的短信服务API更好地与客户互动，促进业务发展',
    sub: [
      {
        title: '全球发送',
        text: '高质量的短信通道，5秒触达用户，99%送达率',
      },
      {
        title: '多通道自动切换',
        text: '拥有丰富的短信通道资源及智能化运维系统，自动切换通道，以保证短信发送的稳定性',
      },
      {
        title: '实时短信状态回执',
        text: '完整的短信发送记录和状态记录查询，透明的计费统计',
      },
      {
        title: '高并发处理',
        text: '复用亿级推送业务的技术架构做服务支撑，为高峰期发送短信提供保障',
      },
      {
        title: '支持上行回复',
        text: '支持短信的上行回复',
      },
      {
        title: '结合推送降低成本',
        text: '针对推送消息未送达的用户补发短信，既保证消息触达又节省成本',
      },
    ],
  },
}
