export default {
  home: 'EngageLab',
  all: '所有',
  hot: '热点新闻',
  other: '相关文章',
  blog: '博客',
  text: '文章详情',
  search: '输入关键词搜索',
  noData: '暂无数据',
  searchBtn: '搜索',
  seo: {
    title: 'EngageLab产品动态_EngageLab博客文章_EngageLab技术内容_极光',
    keysStr: 'EngageLab产品动态,EngageLab博客文章,EngageLab技术内容_极光',
    desc: '阅读Engagelab的文章，了解Engagelab的最新消息、产品等，点击了解更多详情！',
  },
}
