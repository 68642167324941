export default {
  title: '重置密码',
  tips: {
    signTips: ['已经有账户了？', '前去登录'],
  },
  formInfo: {
    password: {
      name: '密码',
      placeholder: '大小写字母+数字组合，长度不少于8个字符',
      errTip: '大小写字母+数字组合，长度不少于8个字符',
      errTip1: '包含至少8个字符',
      errTip2: '不符合包含小写字母(a-z)和大写字母(A-Z)的条件',
      errTip3: '不符合至少包含一个数字(0-9)或一个符号的条件',
    },
    passwordAgain: {
      name: '再次输入密码',
      placeholder: '大小写字母+数字组合，长度不少于8个字符',
      errTip: '大小写字母+数字组合，长度不少于8个字符',
      errTip1: '包含至少8个字符',
      errTip2: '不符合包含小写字母(a-z)和大写字母(A-Z)的条件',
      errTip3: '不符合至少包含一个数字(0-9)或一个符号的条件',
      errTip4: '两次輸输入密码不一致',
    },
  },
  btn: '下一步',
}
