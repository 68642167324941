export default {
  banner: {
    title: '智能化精准消息推送',
    subTitle: 'EngageLab推送通知服务可在3分钟内快速集成SDK，利用智能和准确的推送通知提高用户互动率和留存率',
    mainBtn: '免费试用',
    subBtn: '联系销售',
  },
  tab: {
    title: '应用场景',
    sub: [
      {
        title: '用户行为触发通知',
        descTitle:
          '作为一家推送通知服务提供商，EngageLab可为您提供高效的推送通知服务，并根据操作系统中跟踪的用户行为，在合适的时间自动推送通知',
        desc: ['向新注册用户自动推送欢迎语', '向支付犹豫期用户发放代金券', '向放弃订单用户推荐兴趣物品'],
      },
      {
        title: '基于用户特征通知',
        desc: [
          '移动推送通知服务可以根据交易历史、用户标签、用户画像、兴趣特征和价值评分向用户推送有针对性的产品和服务通知，从而提高客户生命周期价值（LTV）和用户粘性',
        ],
      },
      {
        title: '基于服务变动通知',
        desc: [
          '如果用户状态发生变化，推送通知服务会根据后端业务系统中的用户状态变化，推送交易支付、物流进度、账户异动、投诉反馈、好友消息等重要通知消息',
        ],
      },
      {
        title: '基于地理位置通知',
        desc: ['移动推送通知服务采用了地理围栏技术，触发后可根据用户位置推荐附近的咖啡馆、酒店或餐馆'],
      },
    ],
  },
  reason: [
    {
      title: '如何实现高送达率',
      desc: [
        '由于Google服务覆盖有限和Android生态碎片化等问题，许多通知消息无法送达客户。Engagelab推送通知解决方案和服务不仅支持FCM、APNS系统通道，还支持企业的自建消息发送通道，以及手机厂商的消息发送通道（小米、华为、OPPO、vivo、魅族、荣耀等），帮助企业实现全球触达，将消息送达率大幅提升40%左右',
      ],
    },
    {
      title: '丰富的消息样式',
      desc: [
        'EngageLab推送通知服务支持多样化的通知样式，包括通知栏、大文本、大图片、通知抽屉、全屏通知、信息流、弹窗和自定义样式，可以有效吸引用户注意，提高消息点击率',
      ],
    },
    {
      title: '精准找到推送目标用户',
      desc: ['推送通知服务可通过用户别名、标签、地理围栏、设备标签和用户分群推送有针对性的个性化通知消息，大幅提升消息点击率'],
    },
    {
      title: '完善的数据统计分析',
      desc: [
        '为了帮助开发人员提高应用程序的用户触达率，EngageLab可在整个消息生命周期内提供完整的数据分析服务，包括特定平台/通道的消息漏斗分析和折损分析、有关新增用户、活跃用户、应用程序卸载、通知启用/禁用、消息送达率、点击率的统计数据以及其他后效分析。EngageLab可以根据您的客户生命周期优化移动推送服务',
      ],
    },
  ],
  passageway: {
    title: '搭建连接客户的消息通道',
    subTitle:
      '我们的推送通知服务可帮助开发人员在最合适的时间，以恰当的方式，针对最精准的目标人群，发送最合适的消息，进而提高用户互动率和转化率',
    sub: [
      {
        title: '多平台支持',
        desc: '全面兼容和支持Android 、iOS、Web、MacOS、Windows多个平台，快速集成SDK，为您的应用构建强大的推送能力',
      },
      {
        title: '高送达率',
        desc: 'FCM服务在应用进程离线时就无法正常送达通知消息，通过智能发送策略，优先选择送达率更高的设备厂商推送通道，有效提升消息送达率',
      },
      {
        title: '高效稳定',
        desc: '超百亿级的消息发送量，高并发高可靠、国际专线，多点备份保证系统的稳定、安全和高效',
      },
    ],
  },
}
