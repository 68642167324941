export default {
  banner: {
    title: '实时精准的web通知',
    subTitle:
      '借助EngageLab的网站推送通知服务，无需使用移动应用程序，就能随时向网站订阅者推送营销消息和服务通知，从而改善用户活跃度和转化率',
    mainBtn: '免费试用',
    subBtn: '联系销售',
  },
  tab: {
    title: '应用场景',
    sub: [
      {
        title: '优惠促销通知',
        descTitle: '网站推送通知服务根据用户标签和等级向用户发送不同数量的优惠券，提升用户互动率',
        desc: ['自动向新注册用户推送欢迎信息', '在用户付款犹豫期间，向用户发送优惠券'],
      },
      {
        title: '内容更新的提醒',
        desc: ['如有新公布内容，如创作者、渠道、博客和产品相关内容，将根据订阅偏好向用户发送关于更新内容的浏览器推送通知'],
      },
      {
        title: '服务变动通知',
        desc: [
          '根据用户在系统中的服务状态变更，网站推送通知服务将向用户推送重要的网站通知信息，如付款、物流进度、账号变更、投诉反馈和好友信息等',
        ],
      },
      {
        title: '无效订单的转化',
        desc: ['当购物车内商品长时间未付款或订单被取消时，网站推送通知服务会向用户发送代金券或折扣券，提升订单转化率'],
      },
    ],
  },
  reason: [
    {
      title: '媲美原生的通知体验',
      desc: [
        '网站推送通知服务不仅能从APP中分离出来，并且与多款智能手机上的原生移动推送功能类似。另外，Windows和MacOS平台也支持桌面推送通知服务',
      ],
    },
    {
      title: '支持主流浏览器',
      desc: ['EngageLab浏览器推送通知服务支持Chrome、Safari和火狐等多款主流浏览器。大多数用户都能收到网站推送通知'],
    },
    {
      title: '增加订阅者',
      desc: ['通过engagelab的引导提示功能，在合适的时机向网站用户传达订阅通知消息的价值，可提升约70%的通知订阅率。'],
    },
  ],
  push: {
    title: '尝试快速发送1条WEB通知',
    step: ['输入您要发送的消息内容', '点击「允许」，授于网站通知权限', '展示通知效果'],
    send: '发送通知',
  },
}
