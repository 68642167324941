import index from './zh-CN/index'
import push from './zh-CN/push'
import webPush from './zh-CN/web-push'
import sms from './zh-CN/sms'
import ums from './zh-CN/ums'
import email from './zh-CN/email'
import whatsapp from './zh-CN/whatsapp'
import about from './zh-CN/about'
import contact from './zh-CN/contact'
import signin from './zh-CN/accounts/signin'
import signup from './zh-CN/accounts/signup'
import checkCaptcha from './zh-CN/accounts/checkCaptcha'
import organize from './zh-CN/accounts/organize'
import forgetPwd from './zh-CN/accounts/forgetPwd'
import resetPwd from './zh-CN/accounts/resetPwd'
import pwdUpdated from './zh-CN/accounts/pwdUpdated'
import license from './zh-CN/license'
import docs from './zh-CN/docs'
import blog from './zh-CN/blog'
import twofa from './zh-CN/accounts/2fa'
export default {
  header: {
    home: {
      name: '主页',
    },
    products: {
      name: '产品',
      typeNameList: ['单通道业务', '营销', 'AIGC 服务'],
      sub: ['Omnichannel Messaging', 'App Push', 'Web Push', 'SMS', 'Email', 'WhatsApp Business API'],
    },
    docs: {
      name: '文档',
    },
    aboutUs: {
      name: '关于我们',
    },
    blog: {
      name: '博客',
    },
    lang: {
      name: '简体中文',
      sub: {
        zh_TW: '繁體中文',
        zh_CN: '简体中文',
        en_US: 'English',
        ja_JP: 'にほんご',
        th_TH: 'ภาษาไทย',
      },
    },
    user: {
      login: '登录',
      signup: '注册',
      service: '服务中心',
      account: '账号信息',
      logout: '退出',
    },
  },
  footer: {
    title: '全球领先的客户互动平台',
    remark: 'Copyright © 2023 Metaverse Cloud.  All rights reserved.',
    contactUs: {
      name: '联系我们',
      sub: ['Sales@engagelab.com', '合作伙伴'],
    },
    aboutUs: {
      name: '关于我们',
      sub: ['公司简介', '博客'],
    },
    company: {
      name: '公司',
      sub: ['免费试用', '联络我们'],
    },
    products: {
      name: '产品',
      sub: ['Omnichannel Messaging', 'App Push', 'Web Push', 'SMS', 'Email', 'WhatsApp Business API'],
    },
    support: {
      name: '技术支持',
      sub: ['开发者文档', '隐私协议', '服务协议'],
      content: 'Contact us for further product and service information',
    },
    privacy: {
      text: '单击“接受所有 Cookie”，即表示您同意在您的设备上存储 Cookie，以增强网站导航、分析网站使用情况，帮助我们改善用户体验。 要详细了解我们使用的不同 Cookie，请查看我们的',
      policy: 'Cookie 政策',
      accept: '接受',
      cancel: '取消',
    },
    chart: {
      tip: '您需要接受Cookie隐私政策才能发起咨询！',
    },
  },
  error: {
    404: {
      title: '无法访问页面',
      text: '抱歉，找不到您要访问的页面',
    },
    500: {
      title: '服务器异常',
      text: '抱歉，服务出错了，请稍后刷新页面',
    },
    mainBtn: '返回主页',
    subBtn: '刷新页面',
  },
  index,
  push,
  webPush,
  sms,
  ums,
  email,
  whatsapp,
  about,
  contact,
  signin,
  signup,
  checkCaptcha,
  organize,
  forgetPwd,
  resetPwd,
  pwdUpdated,
  license,
  docs,
  blog,
  twofa,
}
