export default {
  title: '您组织的名称是什么？',
  title1: '创建组织',
  title2: '绑定组织',
  title3: '綁定成功',
  tips: {
    signTips: ['已经有了账户？', '登录'],
    signTips1: ['已经有了組織？', '去绑定'],
    signTips2: ['沒有组织？', '去创建'],
    signTips3: ['返回EDM'],
  },
  formInfo: {
    name: {
      name: '组织名称',
      placeholder: '组织名称',
      errTip: '请输入组织名称',
      errTip1: '长度少于 50 个字符',
      errTip2: '组织名称已存在',
    },
    website: {
      name: '组织网址',
      placeholder: '组织网址',
      errTip: '禁止使用myshopify.com相关链接作为组织网址',
    },
    timeZone: {
      name: '组织时区',
      tip: '（选定后将不可更改）',
      errTip: '请输入组织时区',
    },
  },
  btn: '创建组织',
}
