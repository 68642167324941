export default {
  banner: {
    title: '电子邮件发送平台',
    subTitle: '用于邮件营销的邮件发送API高效稳定，可通过实时准确的数据反馈，优化邮件发送策略，提高送达率',
    mainBtn: '免费试用',
    subBtn: '联系销售',
  },
  scene: {
    title: '全球邮件发送的能力',
    subTitle:
      '您只需几分钟，即可快速集成我们的邮件营销API。EngageLab邮件营销解决方案可向全球用户快速发送邮件，平均3秒送达，且提供详细的数据分析报告',
  },
  reason: [
    {
      title: '更高的送达率',
      desc: [
        'EngageLab可提供邮件发送平台和全面的邮件营销解决方案，提高邮件送达率',
        '助您配置发信域名的SPF、DKIM、DMARC等记录，建立可信关系',
        'IP 池自主维护，和邮箱服务商建立合作，为您提供优质发送通道',
        '黑名单过滤拦截机制，减少无效地址的投递',
        '内容分类，分通道发送，让重要的邮件不再丢失',
      ],
    },
    {
      title: '更快的送达速度',
      desc: [
        'EngageLab邮件营销API可以在数秒内不间断地发送邮件。EngageLab的邮件API操作灵活，功能强大，可以随时处理大量邮件，帮助您在适当的时间更好地触达客户',
        '充分掌握主流邮箱服务商的收信策略，采用自适应发送队列',
        '精细化24h监控，分析发送情况，并持续自动优化发送调度机制',
        '根据用户的业务需求，提供充足的发送IP，提升投递速率',
      ],
    },
    {
      title: '精细的行为数据追踪',
      desc: [
        '智能邮件营销API可以帮助您分析用户行为和送达数据，不断优化邮件营销解决方案',
        '没有数据盲点，提供多样化、多维度统计',
        '自动解析发送日志，归类失败原因，以优化发送效果',
        '提供邮箱服务商收信趋势、邮件阅读位置及设备的分析数据',
      ],
    },
  ],
  passageway: {
    title: '专业的邮件发送服务',
    subTitle: '构建安全、高效、稳定的邮件发送平台，帮助企业优化邮件营销策略，通过提升用户粘性提高用户转化率',
    sub: [
      {
        title: '高效',
        desc: '功能完备的API；Python、Java、 Ruby等多语言代码示例使您的开发更高效',
      },
      {
        title: '安全',
        desc: '隐私数据脱敏，关键操作2FA验证；可设接口调用IP白名单；全站SSL加密',
      },
      {
        title: '稳定',
        desc: '服务稳定性高达99.99%；为您提供7×24h客户支持；分散部署',
      },
    ],
  },
}
