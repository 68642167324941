export default {
  banner: {
    title: '全球领先的客户互动平台',
    subTitle:
      'EngageLab可以提供强大的客户互动平台和多通道消息发送解决方案，帮助企业实现精准的客户触达策略和更高的消息送达率，从而最大限度地提高用户转化率',
    mainBtn: '免费试用',
    subBtn: '联系销售',
  },
  advant: {
    title: '行业领先的技术与服务优势',
    subTitle:
      'EngageLab客户互动平台拥有成熟的产品化能力、多元化产品布局、完善的开发人员工具包及行业领先的解决方案，可以通过不同通道与客户互动',
    sub: [
      {
        title: '全球高送达率',
        text: '亚洲乃至全球送达率最高的通知消息服务商之一',
      },
      {
        title: '百亿消息发送量级',
        text: '日均百亿级消息发送规模，系统服务稳定可靠',
      },
      {
        title: '友好的开发者API',
        text: '通过完善的 API接口 和简单易用的 SDK，在短时间内启动并运行我们的服务',
      },
      {
        title: '专业技术支持',
        text: '专业的技术专家组成的服务团队，为开发者提供技术支持服务',
      },
    ],
  },
  products: {
    title: '助您一臂之力，全方位触达客户',
    subTitle:
      'EngageLab整合了触达客户的主流通道，可通过智能发送策略帮助企业以更低的成本快速触达客户。全面的客户互动解决方案可根据不同的需求进行定制，从而进一步增强客户互动率',
    sub: [
      {
        title: 'Omnichannel Messaging',
        text: '快速构建您的消息管理中台，实现多通道的统一消息发送、管理和统计',
      },
      {
        title: 'Apppush',
        text: '3分钟快速集成SDK，实现智能化精准推送，有效提升用户活跃度和留存率',
      },
      {
        title: 'Webpush',
        text: '不再需要依赖移动APP，您可以随时向网站订阅者推送营销信息和服务通知，提升用戶活跃和转化。',
      },
      {
        title: 'SMS',
        text: '短信触达能力覆盖全球，帮助企业快速实现全球短信发送',
      },
      {
        title: 'Email',
        text: '提供高效稳定的邮件发送API，并通过实时准确的数据反馈，优化发送策略以提高送达率',
      },
      {
        title: 'WhatsApp Business API',
        text: '通过 WhatsApp Business API，与超过20亿的全球用户建立更加紧密和自然的联系',
      },
    ],
  },
  access: {
    title: '专门为开发者设计的极简接入流程',
    subTitle: '简洁易用的通信API设计、安全稳定的SDK和完善的开发文档可以帮助开发人员快速接入服务，充分利用EngageLab客户互动平台',
  },
  comments: {
    title: '深受全球众多企业的信赖',
    subTitle: 'EngageLab 凭借成熟稳定的技术以及专业细致的服务获得众多客户的信赖与支持',
    sub: [
      {
        name: 'Tyler Elise',
        profession: '产品负责人',
        text: 'EngageLab的多通道消息发送服务可帮助我们缩短产品研发时间，为我们提供简单易用的解决方案和方便快捷的客户互动平台，进而优化用户体验',
      },
      {
        name: 'Arika Johnson',
        profession: '客戶分析团队负责人',
        text: '我们选择EngageLab，因为它具备出色的多通道消息发送能力，以及通过多个全球通道提供的灵活互动解决方案，能够帮助我们更加轻松触达位于全球不同地区的客户',
      },
      {
        name: 'James Maddison',
        profession: '客户增长高级总监',
        text: 'EngageLab是一个直观易用的交互式客户互动平台。我们非常感谢EngageLab的客户支持团队提供的专业支持',
      },
      {
        name: 'Robert Glaser',
        profession: '客户增长高级总监',
        text: '我们选择EngageLab，因为它的客户互动平台可以帮助我们实现高效的消息发送，同时根据复杂和高容量的消息发送需求进行数据驱动决策，进而帮助我们提高用户获取率、转化率、互动率和留存率',
      },
    ],
  },
  metaBanner: {
    title: 'WhatsApp 大中华区战略合作伙伴',
    subTitle: 'EngageLab 提供官方授权的 WhatsApp 消息通道，快速连接全球 20 亿 WhatsApp 用户',
  },
}
