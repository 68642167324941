export default {
  addPhoneTip: '为了保障您的账号安全，及时接收系统紧急通知，请完善个人信息。',
  title: '建立您的 Engagelab',
  addInfoTitle: '完善信息',
  getCode: '获取验证码',
  refresh: '刷新',
  sure: '确定',
  firstName: '名字',
  lastName: '姓氏',
  nameTip: '请输入名字',
  nameTip2: '请输入姓氏',
  tips: {
    signTips: ['已经有账户了', '登录'],
    splitTip: '或',
    servicePolicy: '服务协议',
    privacy: '隐私协议',
    assistTips: ['按一下「建立账户」，即表示我已阅读并同意我们', '和'],
  },
  formInfo: {
    email: {
      name: '电子邮件地址',
      placeholder: '建议使用公司电子邮箱',
      errTip: '请输入您的工作电子邮箱',
      errTip1: '电子邮件无效', //
      errTip2: '已有其他账户使用了此电子邮箱',
    },
    password: {
      name: '密码',
      placeholder: '大小写字母+数字组合，长度不少于8个字符',
      errTip: '大小写字母+数字组合，长度不少于8个字符',
      errTip1: '包含至少8个字符',
      errTip2: '不符合包含小写字母(a-z)和大写字母(A-Z)的条件',
      errTip3: '不符合至少包含一个数字(0-9)或一个符号的条件',
    },
    phone: {
      name: '电话号码',
      placeholder: '请输入电话号码',
      errTip: '请输入电话号码',
    },
    code: {
      name: '短信验证码',
      placeholder: '请输入验证码',
      errTip: '验证码为空或错误',
    },
  },
  btn: '创建账户',
}
