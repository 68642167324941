export default {
  banner: {
    title: 'WhatsApp Business API',
    subTitle: '作为WhatsApp Business的解决方案提供商，EngageLab帮助企业使用WhatsApp Business API与全球二十多亿用户建立密切联系',
    // mainBtn: '用 Facebook 登入',
    mainBtn: '免费试用',
    subBtn: '联系销售',
  },
  scene: {
    title: '丰富的WhatsApp Business API消息能力',
    sub: [
      {
        title: '文本消息',
        text: '支持向用户发送纯文本+emoji符号消息',
      },
      {
        title: '视频',
        text: '支持向用户发送视频消息，并可直接在应用中播放',
      },
      {
        title: '图片',
        text: '支持向用户发送图文消息，支持PNG、JPG、JPEG图片格式',
      },

      {
        title: '交互式按钮',
        text: '交互按钮支持设置不同的文案和触发策略，以便引导用户达成转化',
      },
      {
        title: '附件',
        text: '支持向用户发送附件消息，例如PDF或其他附件',
      },
      {
        title: '地点',
        text: '支持向用户发送位置消息，包含经度和纬度坐标',
      },
    ],
  },
  reason: [
    {
      title: '安全验证',
      desc: [
        '安全性是用户注册、登录和支付流程的重中之重。WhatsApp OTP服务能有效节省消息发送成本和时间，提升消息送达率，增强信息安全性，并优化用户体验。通过WhatsApp获得的用户可转化为额外的商机。在WhatsApp营销活动中，企业可使用一次性密码确保用户安全',
      ],
    },
    {
      title: '拉新获客',
      desc: [
        '借助嵌入式进入点，用户能通过WhatsApp Message API在多个渠道上发起WhatsApp会话，包括Google或Facebook广告、电商网站等自营渠道、app或邮件签名或呼叫中心系统。这些方式有助于提升用户体验，实现更高效通信，进而提高用户互动率和留存率',
      ],
    },
    {
      title: '用户持续转化',
      desc: [
        'WhatsApp营销活动是提高用户转化率的有效方式。使用WhatsApp引起用户对新产品、品牌和折扣促销以及专场营销活动的关注。我们的解决方案有助于提升客户互动率、生命周期价值（LTV）和复购率。此外，还可以通过会员计划、奖励积分、限时优惠券和会员资格到期提醒等活动唤醒休眠用户',
      ],
    },
    {
      title: '重要通知',
      desc: [
        'WhatsApp Message API通过WhatsApp发送有关账户更新、交易、物流信息和服务的重要通知，利用平台的高用户互动率，确保用户不会错过重要任务信息',
      ],
    },
  ],
  passageway: {
    title: '强大的 WhatsApp Busines 解决方案',
    sub: [
      {
        title: '全域通道服务',
        desc: 'WhatsApp 通道可以与Engagelab的其他通道产品一起使用，实现为用户提供全域多通道的消息服务',
      },
      {
        title: '功能強大的 API',
        desc: 'WhatsApp for Business 解决方案,可帮助企业尽可能拉近与受众的距离，进行实时的沟通',
      },
      {
        title: '数据安全保障',
        desc: '作为Meta（Facebook）官方授权服务商，EngageLab会把数据托管在我们的内部数据中心。官方支持的API接口符合GDPR标准且通过WhatsApp的端到端加密，确保安全',
      },
    ],
  },
  steps: {
    title: '如何快速开始使用',
    sub: [
      {
        title: '注册账号',
        text: '注册一个免费的EngageLab账号',
      },
      {
        title: '开通 API',
        text: '登录EngageLab后台，申请开通WhatsApp business API能力，Meta官方也会参与审核相关信息',
      },
      {
        title: '开始使用',
        text: '审核通过且开通服务后，即可通过REST API开发、调试和正式发布您的应用',
      },
    ],
  },
}
