export default {
  title: `双因子验证`,
  subTitle: `你的账户已开启双重验证功能，因此你必须完成此额外的登录步骤。`,
  error: `请输入有效验证码`,
  tip: `请输入谷歌验证器app提供的验证码`,
  contact1: `联系我们的`,
  contact2: `客服`,
  return: `返回`,
  submit: `提交验证`,
}
