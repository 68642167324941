export default {
  title: '找回密码',
  subTitle: '输入您的电子邮箱地址，以请求重置密码。您将受到一封包含详细说明的电子邮件。',
  tips: {
    signTips: ['已经有账户了？', '前去登录'],
  },
  formInfo: {
    email: {
      name: '电子邮箱',
      placeholder: '电子邮箱地址',
      errTip: '请输入电子邮箱地址',
      errTip1: '电子邮箱无效',
      errTip2: '电子邮箱地址不存在，是否',
    },
  },
  btn: '下一步',
}
