export default {
  banner: {
    title: '全通道消息触达平台',
    subTitle: '快速构建您的消息管理中台，实现多通道统一消息发送、管理和统计',
    mainBtn: '免费试用',
    subBtn: '联系销售',
  },
  scene: {
    title: '全域触达能力',
    subTitle:
      'EngageLab整合了触达用户的主流通道，可通过Notify API和ConverStations API帮助您快速构建用户触达系统。多通道营销平台和解决方案可以帮助企业更好地触达处于不同接触点的客户',
  },
  reason: [
    {
      title: '更多通道，无处不达',
      desc: [
        '多通道营销平台包括APP推送、邮件、短信、Whatsapp等，支持多通道触达客户',
        '稳定可靠的自建通道',
        ' 主流通道全覆盖 ',
        ' 快速接入',
        ' 丰富易用的API接口',
      ],
    },
    {
      title: '智能发送策略',
      desc: [
        '多通道消息发送平台，配备智能通信API，可以更加智能地实现更高的消息送达率',
        '多通道补发',
        ' 多通道分发',
        ' 灵活的策略配置',
        ' 更低的发送成本',
      ],
    },
    {
      title: '全链路数据分析',
      desc: [
        '多通道消息发送平台可以分析客户互动数据，帮助您制定更加适用的营销解决方案和后续计划',
        '消息全生命周期管理',
        ' 消息折损分析',
        ' 用户消息行为分析',
        ' 后效分析与运营优化',
      ],
    },
  ],
  function: {
    title: '强大易用的API',
    subTitle: '您的应用程序可以利用标准REST API调用所有关键功能。以下功能可以帮助您更好地运营多通道营销平台，并触达客户',
    sub: [
      {
        title: '普通消息发送',
        text: '自由选择发送通道，消息内容格式智能校准以遵循各通道规范',
      },
      {
        title: '模板消息发送',
        text: '通过模板提前预设好消息格式和变量，发送消息时只需一次变量传值即可对所有通道生效',
      },
      {
        title: '消息撤回',
        text: '支持撤回24小时内发出的消息，避免因人为或其他因素造成的发送事故',
      },
      {
        title: '用户管理',
        text: '管理用户的多通道联系方式、用户标签等信息，用于补发、分发和统计分析',
      },
      {
        title: '素材系统',
        text: '对多通道的图片、视频、文件等素材进行统一管理，用于邮件附件、富媒体消息的发送',
      },
      {
        title: '批量请求',
        text: '一次请求支持批量设置多个目标用户和对应的消息内容，减轻企业服务器的请求压力',
      },
    ],
  },
}
